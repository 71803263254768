import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

import classes from "./styles/ContactUs.module.css";
import Container from "../components/Container/Container";

const ContactUs = () => {
	return (
		<div className="ContactUs">
			<div className="navbar">
				<Navbar />
			</div>

			<div className={classes.content}>
				<Container>
					<h1>Contact Us</h1>
					<p>Email: info@footprintnz.com</p>
					<p>Phone: 09 - 627 3964</p>
					<p>Address: 2 Kinross Street, Blockhouse Bay, Auckland, New Zealand, 600</p>
					<iframe title="Map" className={classes.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3189.6318078112026!2d174.699094915373!3d-36.92306677992348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d441d46aa9147%3A0xd8f1cde5d33a1fe4!2s2%20Kinross%20Street%2C%20Blockhouse%20Bay%2C%20Auckland%200600!5e0!3m2!1sen!2snz!4v1609932020352!5m2!1sen!2snz" width="600" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
					<p>Got any questions?</p>
					<p>Have a look at our Frequently Asked Questions page <a href="/FAQ/">here</a></p>
				</Container>
			</div>

			<div className="footer">
				<Footer />
			</div>
		</div>
	)
}

export default ContactUs;
